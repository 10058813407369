
/*! #######################################################################

	MeanMenu 2.0.7
	--------

	To be used with jquery.meanmenu.js by Chris Wharton (http://www.meanthemes.com/plugins/meanmenu/)

####################################################################### */

/* hide the link until viewport size is reached */
a.meanmenu-reveal {display: none;}
/* when under viewport size, .mean-container is added to body */
.mean-container .mean-bar {
  background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
  float: left;
  min-height: 42px;
  padding: 4px 0;
  position: relative;
  width: 100%;
  z-index: 8;
}
.mean-container a.meanmenu-reveal {
  color: #fff;
  cursor: pointer;
  display: block;
  font-weight: 500;
  height: 22px;
  line-height: 22px;
  margin-top: 5px;
  padding: 13px 13px 11px;
  position: absolute;
  right: 0;
  text-decoration: none;
  top: 0;
  width: 28px;
  transition: all 0.3s ease-in-out;
}
.mean-container a.meanmenu-reveal span {
  background: #fff none repeat scroll 0 0;
  border-radius: 25px;
  display: block;
  height: 3px;
  margin-top: 4px;
  transition: all 0.3s ease-in-out;
}
.mean-container a.meanmenu-reveal:hover span {
  background: #fff;
}
.sticky .mean-container a.meanmenu-reveal:hover span {
  background: #EB3E32;
}
.mean-container a.meanmenu-reveal span:first-child{margin: 0}
.mean-container .mean-nav {
  background: #ffffff none repeat scroll 0 0;
  float: left;
  margin-top: 46px;
  position: absolute;
  width: 100%;
}
.mean-container .mean-nav ul {
	padding: 0;
	margin: 0;
	width: 100%;
	list-style-type: none;
}
.mean-nav > ul {
  max-height: 320px;
  overflow-y: scroll;
}
.mean-container .mean-nav ul li {
	position: relative;
	float: left;
	width: 100%;
}
.mean-container .mean-nav ul li a {
    border-top: 1px solid #ccc;
    color: #383838;
    display: block;
    float: left;
    font-size: 12px;
    font-weight: 500;
    margin: 0;
    padding: 1em 5%;
    text-align: left;
    text-decoration: none;
    text-transform: uppercase;
    width: 90%;
}
.mean-container .mean-nav ul li a:hover {color: #00d379;}
.mean-container .mean-nav ul li li a {
	width: 80%;
	padding: 1em 10%;
	border-top: 1px solid #ccc;
	border-top: 1px solid #ccc;
	opacity: 1;
	filter: alpha(opacity=1);
	text-shadow: none !important;
	visibility: visible;
}
.mean-container .mean-nav ul li.mean-last a {
    border-bottom: 1px solid #cccccc;
    margin-bottom: 0;
}
.mean-container .mean-nav ul li li li a {
	width: 70%;
	padding: 1em 15%;
}
.mean-container .mean-nav ul li li li li a {
	width: 60%;
	padding: 1em 20%;
}
.mean-container .mean-nav ul li li li li li a {
	width: 50%;
	padding: 1em 25%;
}
.mean-container .mean-nav ul li a:hover {`
	background: #252525;
	background: rgba(255,255,255,0.1);
}
.mean-container .mean-nav ul li a.mean-expand {
  background: rgba(255, 255, 255, 0.1) none repeat scroll 0 0;
  border: medium none;
  font-weight: 600;
  height: 25px;
  line-height: 25px;
  margin-top: 1px;
  padding: 9px 8px;
  position: absolute;
  right: 0;
  text-align: center;
  top: -1px;
  width: 25px;
  z-index: 2;
}
.mean-container .mean-nav ul li a.mean-expand:hover {
  background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
  color: #eb3e32;
}
.mean-container .mean-push {
	float: left;
	width: 100%;
	padding: 0;
	margin: 0;
	clear: both;
}
.mean-nav .wrapper {
	width: 100%;
	padding: 0;
	margin: 0;
}
/* Fix for box sizing on Foundation Framework etc. */
.mean-container .mean-bar, .mean-container .mean-bar * {
	-webkit-box-sizing: content-box;
	-moz-box-sizing: content-box;
	box-sizing: content-box;
}
.mean-remove {display: none !important;}
.mean-nav span {display: none;}

.mean-container .mean-nav ul li a:hover {color: #EB3E32;}


